import React, { useState } from "react";
import {
  useActiveStep,
  useCalibers,
  useData,
  usePeriod,
  useSetCalibers,
  useSetNextStep,
  useSetPrevStep,
  useSteps,
} from "../../self-assessment-form-context/index";
import { checkCaliberValidation } from "../../../util/CheckCaliberValidation";
import { useSelector } from "react-redux";
import { submitAssessmentHandler } from "../../self-assessment-form-context/ctx-handler";
import Loader from "../../../../../../components/Loader/Loader";
import "./buttons-row.css";
import { Backdrop } from "@mui/material";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
import {
  ANSWER_NO,
  ANSWER_NOT_APPLICABLE,
} from "../../../../../../common/constants/caliberAnswers";
import {
  RE_SUBMIT_STATUS,
  RE_SUBMIT_STATUS_API_VALUE,
} from "../../../../../../common/constants/assessmentStatuses";
import request from "../../../../../../common/request";

export const ButtonsRow = ({ step, setErrors, goToSuccessScreen }) => {
  const next = useSetNextStep();
  const establishmentProfileId = useSelector(
    (state) => state?.establishment.establishmentProfile.id
  );
  const prev = useSetPrevStep();
  const period = usePeriod();
  const activeStep = useActiveStep();
  const ctxData = useData();
  const steps = useSteps();
  const calibers = useCalibers();
  const setCalibers = useSetCalibers();
  const caliber = calibers?.[step?.caliber_id];

  const [loadingSubmit, setLoadingSubmit] = useState(false); // Define loadingSubmit state variable
  const violators = useSelector((state) => state?.assessmentForm.violators);
  const nonComplianceText = useSelector(
    (state) => state?.assessmentForm.nonComplianceText
  );
  const didUpdateViolatorsOrCompliance = useSelector(
    (state) => state?.assessmentForm.didUpdate
  );

  const handleSendNonComplianceAndViolators = async () => {
    const status = period.assessment?.status?.split(" ")?.join("");
    const isReturned =
      period?.audit_status === RE_SUBMIT_STATUS ||
      period?.audit_status === RE_SUBMIT_STATUS_API_VALUE;
    const periodId = period.id;
    const assessmentVersion = period.assessment_version;
    const periodsAssessmentId = period.period_assessment_id;
    const assessmentId = period.assessment.id;
    const payload = {
      period_id: periodId,
      assessment_id: assessmentId,
      assessment_caliber: step?.caliber_id,
      period_assessment_id: periodsAssessmentId,
      answer: isReturned ? caliber.new_answer : caliber.answer,
      assessment_version: assessmentVersion,
      note: nonComplianceText,
      violation_number: Number(violators),
      status,
    };

    const baseUrl = `/v1/establishments/${establishmentProfileId}/`;
    const url = isReturned
      ? baseUrl + "returned_assessment"
      : baseUrl + "assessments";
    const res = await request(url, "PATCH", { submission: payload })
      .then(async (res) => {
        if (res.status == 200) {
          setCalibers({
            [step?.caliber_id]: {
              ...caliber,
              violation_number: violators,
              note: nonComplianceText,
            },
          });
        }
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });

    return res;
  };

  const handleNext = async () => {
    setErrors({});
    if (step?.type === "qiwa") {
      return next();
    }

    if (step?.type === "addons") {
      if (step?.hasAgreed) {
        setLoadingSubmit(true); // Set loadingSubmit to true before submitting
        // submit assessment
        // try {
        // Call submitAssessmentHandler with loadingSubmit and setLoadingSubmit
        await submitAssessmentHandler({
          assessment: period,
          calibers,
          data: ctxData,
          establishmentProfileId,
        })
          .then((result) => {
            goToSuccessScreen();
          })
          .catch((error) => {
            console.error("Error submitting assessment:", error);
            setLoadingSubmit(false); // Set loadingSubmit to false if there's an error
            if (error) {
              setErrors({
                step,
                missingAnswer: error,
              });
            } else {
              setErrors({
                step,
                agreement: "يجب الموافقة على الشروط والأحكام",
              });
            }
          });
        // } catch (error) {
        //   console.error("Error submitting assessment:", error);
        //   setLoadingSubmit(false); // Set loadingSubmit to false if there's an error
        //   setErrors({ step, agreement: "يجب الموافقة على الشروط والأحكام" });
        // }
      } else {
        setErrors({ step, agreement: "يجب الموافقة على الشروط والأحكام" });
        return;
      }
      return;
    }
    const isNonComplianceMandatory =
      ((caliber.answer === ANSWER_NOT_APPLICABLE || caliber.new_answer === ANSWER_NOT_APPLICABLE) &&
        caliber.is_visible_establishment_comment_not_applicable) ||
      ((caliber.answer === ANSWER_NO || caliber.new_answer === ANSWER_NO) &&
        caliber.is_visible_establishment_comment_no);

    const isViolatorsMandatory =
      ((caliber.answer === ANSWER_NOT_APPLICABLE || caliber.new_answer === ANSWER_NOT_APPLICABLE)&&
        caliber.is_visible_establishment_violation_number_not_applicable) ||
      ((caliber.answer === ANSWER_NO || caliber.new_answer === ANSWER_NO) &&
        caliber.is_visible_establishment_violation_number_no);
    const { isValid, scrollTarget, errors } = checkCaliberValidation(
      caliber,
      violators,
      nonComplianceText
    );

    if (isValid) {
      if (
        (isNonComplianceMandatory || isViolatorsMandatory) &&
        didUpdateViolatorsOrCompliance
      )
        await handleSendNonComplianceAndViolators();
      return next();
    } else if (!isValid) {
      if (Object.keys(errors)?.length) {
        setErrors(errors);
      }
      if (scrollTarget) {
        document
          .getElementById(scrollTarget)
          ?.scrollIntoView({ behavior: "smooth" });
      }
    } else if (activeStep >= steps?.length) {
      // alert("submit");
      // push("/dashboard/self/success");
    } else {
      if (false) {
        // alert('submit');
      } else {
        next();
      }
    }
  };

  if (loadingSubmit) {
    return (
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingSubmit}
      >
        <Loader />
      </Backdrop>
    );
  } else {
    return (
      <div className="mt-6 w-full flex justify-end gap-x-4 ">
        {activeStep?.index === 0 &&
        activeStep?.categoryIndex === 0 &&
        activeStep?.caliberIndex === 0 ? null : (
          <button
            onClick={(e) => {
              setErrors({});
              prev(e);
            }}
            className={
              "w-[118px]  rounded-[6px] shadow-sm shadow-[#00000026] border-[1.5px] border-[#F5961E] text-[#F5961E]  focus:outline-none mobile-button"
            }
            style={{ height: "40px" }}
          >
            <Text Tag={"span"} textType="roman" fontSize={"font-16"}>
              {"السابق"}
            </Text>
          </button>
        )}

        <button
          onClick={handleNext}
          className={
            "w-[118px]  rounded-[6px] shadow-sm shadow-[#00000026] bg-ims-dark_orange text-white  focus:outline-none mobile-button"
          }
          style={{ height: "40px" }}
        >
          <Text Tag={"span"} textType="roman" fontSize={"font-16"}>
            {step?.type === "addons" ? "إرسال" : "التالي"}
          </Text>
        </button>
      </div>
    );
  }
};
