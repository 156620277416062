export const ExtractCalibersArrayFromCaliberCategories = (caliberCategories) => {
    let calibersArray = [];

    if (!caliberCategories) {
        return calibersArray;
    }
    // First way: Using caliberCategories.labels if it exists and has more than one element
    if (caliberCategories.labels && caliberCategories.labels.length > 0) {
        // For non-audit report, collect calibers from all labels
        caliberCategories.labels.forEach(label => {
          if (label.calibers && label.calibers.length > 0) {
            calibersArray = [...calibersArray, ...label.calibers];
          }
        });
    }
  
    // Second way: If calibersArray is still empty, use caliberCategories.calibers
    if (calibersArray.length === 0 && caliberCategories.calibers) {
      calibersArray = caliberCategories.calibers;
    }
  
    return {
      calibersArray,
      caliberCategories
    };
  };