import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import "./billInfo.scss";
import FooterLogo from "../../../../public/img/IMS-MHRSD-logo.svg";
import { fetchBillInfo } from "../../../actions/billInfo";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";

export class BillInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      billData: {},
    };
  }
  componentDidMount() {
    this.props.getBillInfo(this.props.match.params.billId).then((data) => {
      this.setState({ billData: data });
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.billId !== prevProps.match.params.billId) {
      window.location.reload();
    }
  }
  render() {
    const { isError, isLoading } = this.props;
    const { billData } = this.state;

    return (
      <div>
        {isLoading ? (
          <div className="loader-div">
            <Loader />
          </div>
        ) : isError ? (
          <Text Tag="div" textType="p3" className="bill-info-title__text">
            يوجد خطأ ما. الرجاء التأكد من رقم الفاتوره وأعد المحاولة مرة أخرى.
          </Text>
        ) : (
          <Fragment>
            <header className="bill-info-header ">
              <div className="bill-info-header-wrapper">
                <Text
                  Tag="div"
                  isBold
                  textType="h5"
                  className="bill-info-title"
                >
                  نتيجة المسح
                </Text>
              </div>
            </header>

            <Text
              Tag="div"
              isBold
              textType="h5"
              className="bill-info-title__text"
            >
              تفاصيل الفاتورة
            </Text>

            <div className="bill-info-card">
              <div className="green-info-bar">
                <Text Tag="div" textType="p3" className="green-info-bar-text">
                  متوافق مع هيئة الزكاة والضرائب والجمارك
                </Text>
              </div>

              <div className="bill-data">
                <Text Tag="p" textType="p4" className="bill-data__header">
                  اسم البائع
                </Text>
                <Text Tag="p" textType="p4" className="bill-data__text" isBold>
                  {billData.name}
                </Text>
              </div>
              <div className="bill-data">
                <Text Tag="p" textType="p4" className="bill-data__header">
                  الرقم الضريبي
                </Text>
                <Text Tag="p" textType="p4" className="bill-data__text" isBold>
                  {billData.tax_number}
                </Text>
              </div>
              <div className="bill-data">
                <Text Tag="p" textType="p4" className="bill-data__header">
                  وقت / تاريخ الفاتورة
                </Text>
                <Text Tag="p" textType="p4" className="bill-data__text" isBold>
                  {`${moment(billData.create_date).format(
                    "MMM DD YYYY h:mm a"
                  )}`}
                </Text>
              </div>
              <div className="bill-data">
                <Text Tag="p" textType="p4" className="bill-data__header">
                  القيمة المضافة %15
                </Text>
                <Text Tag="p" textType="p4" className="bill-data__text" isBold>
                  {`${billData.vat_amount} ريال`}
                </Text>
              </div>
              <div className="bill-data">
                <Text Tag="p" textType="p4" className="bill-data__header">
                  المبلغ
                </Text>
                <Text Tag="p" textType="p4" className="bill-data__text" isBold>
                  {`${billData.amount} ريال`}
                </Text>
              </div>
              <div className="bill-data">
                <Text Tag="p" textType="p4" className="bill-data__header">
                  المبلغ الإجمالي
                </Text>
                <Text Tag="p" textType="p4" className="bill-data__text" isBold>
                  {`${billData.totalAmount} ريال`}
                </Text>
              </div>
            </div>
            <footer
              className="establishment-footer"
              style={{ marginRight: "0" }}
            >
              <img
                className="establishment-footer-logo-mobile"
                src={FooterLogo}
                alt="IMS MHRSD logo"
              />
              <Text textType="h9" className="establishment-footer-copyright">
                جميع الحقوق محفوظة لبرنامج التقييم الذاتي © 2025
              </Text>
              <img
                className="establishment-footer-logo-desktop"
                src={FooterLogo}
                alt="IMS MHRSD logo"
              />
            </footer>
          </Fragment>
        )}
      </div>
    );
  }
}

BillInfo.defaultProps = {};

BillInfo.propTypes = {
  getBillInfo: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.billInfo.isLoading,
  isError: state.billInfo.error,
});

const mapDispatchToProps = (dispatch) => ({
  getBillInfo: (billId) => dispatch(fetchBillInfo(billId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillInfo);
