import toastr from "toastr";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallError, endAjaxCall } from "./ajaxStatusActions";
import {
  loadEstablishmentAssessment,
  loadEstablishmentAciveAssessment,
} from "./establishmentAssessmentActions";
import request from "../common/request";
import { getUserInfo } from "../../src/actions/authActions";
import { TRAINING } from "../common/constants/paymentStatuses";

export function loadEstablishmentProfileSuccess(establishmentProfile) {
  return {
    type: types.LOAD_ESTABLISHMENTPROFILE_SUCCESS,
    establishmentProfile,
  };
}

export function loadEstablishmentsSuccess(establishments) {
  return { type: types.LOAD_ESTABLISHMENTS_SUCCESS, establishments };
}

export function getMajorViolationResultSuccess(majorViolationResult) {
  return { type: types.GET_MAJORVIOLATIONRESULT_SUCCESS, majorViolationResult };
}

export function loadPaymentServicesSuccess(reopen_assessment_services) {
  return {
    type: types.LOAD_PAYMENTSERVICES_SUCCESS,
    reopen_assessment_services,
  };
}
export function loadPaymentServicesByTypeSuccess(paymentServices, isAlreadyPaid) {
  return {
    type: types.LOAD_PAYMENTSERVICESBYTYPE_SUCCESS,
    paymentServices,
    isAlreadyPaid
  }
}

export function loadingPaymentServices() {
  return {
    type: types.LOADING_PAYMENT_SERVICES,
  };
}
export function loadPaymentServicesError() {
  return {
    type: types.LOAD_PAYMENT_SERVICES_FAIL,
  };
}

export function loadTrainingPaymentServicesSuccess(training_services) {
  return {
    type: types.LOAD_TRAININGPAYMENTSERVICES_SUCCESS,
    training_services,
  };
}

export function loadPaymentBillsSuccess(bills) {
  return { type: types.LOAD_PAYMENTBILLS_SUCCESS, bills };
}
export function loadEstablishmentBillsSuccess(bills) {
  return { type: types.LOAD_ALL_ESTABLISHMENT_BILLS_SUCCESS, bills };
}
export function loadingSubmitPayment(isLoading = true, details = {}) {
  if (isLoading)
    return {
      type: types.LOADING_SUBMIT_PAYMENT,
    };
  else
    return {
      type: types.END_LOADING_SUBMIT_PAYMENT,
      details,
    };
}

export const saveEstablishmentProfile =
  (establishmentId, profileData, CertifiedByChamberOfCommerce, CertifiedByBankAccount, userId) => async (dispatch) => {
    const data = new FormData();

    data.append("CertifiedByChamberOfCommerce", CertifiedByChamberOfCommerce);
    data.append("CertifiedByBankAccount", CertifiedByBankAccount);

    data.append("establishment_details", JSON.stringify(profileData));
    return request(`/users/profile/${establishmentId}/${userId}`, "PUT", data, {
      upload: true,
    })
      .then(async (res) => {
        if (res.status && /^(4|5)\d\d/.test(res.status)) {
          const result = await res.json();

          throw result;
        }

        // localStorage.removeItem("selected");
        // dispatch(loadEstablishments());
        return res;
      })
      .then((res) => {
        dispatch({
          type: types.SAVE_ESTABLISHMENT_PROFILE_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };

export const updateEstablishmentProfile =
  ({ key, value }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_ESTABLISHMENT_PROFILE,
      payload: { key, value },
    });
  };

export function loadEstablishmentProfile(establishmentProfile) {
  return (dispatch) => {
    dispatch(loadEstablishmentProfileSuccess(establishmentProfile));
  };
}

export function loadPaymentServices(establishment_id) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    dispatch(loadingPaymentServices());

    return request(`/payments/services/${establishment_id}`)
      .then((res) => res.json())
      .then((res) => {
        console.log("--res-of-payments-services", res);
        dispatch(loadPaymentServicesSuccess(res ? res : []));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        dispatch(loadPaymentServicesError());
        throw error;
      });
  };
}

export function loadPaymentServicesByType(establishment_id, period_assessment_id, serviceType) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    dispatch(loadingPaymentServices());

    return request(`/payments/services/${establishment_id}`, "POST", {
      period_assessment_id: period_assessment_id,
      serviceType: serviceType,
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(loadPaymentServicesByTypeSuccess(res.paymentTypes, res.isAlreadyPaid));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        dispatch(loadPaymentServicesError());
        throw error;
      });
  };
}

export function loadTrainingPaymentServices(establishment_id) {
  return function (dispatch) {
    dispatch(beginAjaxCall());

    return request(`/payments/services/${establishment_id}/${TRAINING}`)
      .then((res) => res.json())
      .then((res) => {
        dispatch(loadTrainingPaymentServicesSuccess(res ? res : []));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function submitPayment(
  establishment_id,
  payment_service_id,
  period_id,
  assessment_id,
  assessment_version
) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    dispatch(loadingSubmitPayment());

    const payment = {
      payment_bill: {
        payment_service_id: payment_service_id,
        period_id: period_id,
        assessment_id: assessment_id,
        assessment_version: Number(assessment_version),
      },
    };

    return request(
      `/payments/establishments/initiate/${establishment_id}`,
      "POST",
      payment
    )
      .then((res) => {
        if (res.status == 424) {
          throw res.status;
        } else if (res.status != 200) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        dispatch(endAjaxCall());
        dispatch(
          loadingSubmitPayment(false, {
            establishmentId: establishment_id,
            serviceId: payment_service_id,
            periodId: period_id,
            assessmentId: assessment_id,
            assessmentVersion: assessment_version,
          })
        );
        return res;
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        dispatch(loadingSubmitPayment(false));
        throw error;
      });
  };
}

export function submitPaymentWithCorrection(
  establishment_id,
  payment_service_ids,
  period_id,
  assessment_id,
  assessment_version
) {
  return function (dispatch) {
    dispatch(loadingSubmitPayment());
    dispatch(beginAjaxCall());
    let payment = {
      payment_bill: {
        payment_service_ids: payment_service_ids,
        period_id: period_id,
        assessment_id: assessment_id,
        assessment_version: Number(assessment_version),
      },
    };

    return request(
      `/payments/establishments/initiate_detailed_report/${establishment_id}`,
      "POST",
      payment
    )
      .then((res) => {
        if (res.status == 424) {
          throw res.status;
        } else if (res.status != 200) {
          throw res;
        } else return res.json();
      })
      .then((res) => {
        dispatch(
          loadingSubmitPayment(false, {
            establishmentId: establishment_id,
            serviceId: payment_service_ids,
            periodId: period_id,
            assessmentId: assessment_id,
            assessmentVersion: assessment_version,
          })
        );
        dispatch(endAjaxCall());
        return res;

        // window.open(res.payment_bill.payment_resp_checkout_URL, "_blank");
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(loadingSubmitPayment(false));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function submitTrainingPayment(
  establishment_id,
  payment_service_id,
  training_id
) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    const payment = {
      payment_service_id: payment_service_id,
      training_id: training_id,
    };

    return request(`/v1/${establishment_id}/trainings/payment`, "POST", payment)
      .then((res) => {
        if (!res.ok) {
          if (res.status == 400) {
            throw res.json();
          }
          throw res.statusText;
        }
        return res.json();
      })
      .then((res) => {
        dispatch(endAjaxCall());
        window.location.href = res.payment_resp_checkout_URL;
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function loadEstablishments() {
  return async function (dispatch) {
    dispatch(beginAjaxCall());

    return request("/users/establishments")
      .then((res) => res.json())
      .then((res) => {
        dispatch(
          loadEstablishmentsSuccess(
            res.establishments ? res.establishments : []
          )
        );

        dispatch(endAjaxCall());
        if (!res.establishments || res.establishments.length === 0) {
          toastr.error("أنت غير مفوض على أي منشأة حاليا");
          window.location =
            process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/home";
        }
        const establishmentProfile = JSON.parse(
          localStorage.getItem("selected")
        );

        const selected =
          establishmentProfile &&
          res.establishments.find((e) => e.id === establishmentProfile.id);
        if (selected) {
          localStorage.removeItem("selected");
          localStorage.setItem("selected", JSON.stringify(selected));
          dispatch(loadEstablishmentProfile(selected));
        } else {
          localStorage.removeItem("selected");
        }
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function confirmAgreement() {
  return function (dispatch) {
    dispatch(beginAjaxCall());

    return request("/users/disclaimer_approve")
      .then((res) => res.json())
      .then((res) => {
        dispatch(getUserInfo());
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function getMajorViolationResult(period_id, assessment_id) {
  let url = "/v1/establishments/violations/" + period_id + "/" + assessment_id;
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request(url)
      .then((res) => res.json())
      .then((establishmentResult) => {
        dispatch(
          getMajorViolationResultSuccess({
            violated_calibers: establishmentResult.calibers,
          })
        );
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function submitMajorViolationApproval(period_id, assessment_id) {
  let url =
    "/v1/establishments/violations/confirmed/" +
    period_id +
    "/" +
    assessment_id;
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request(url)
      .then((establishmentResult) => {
        dispatch(loadEstablishmentAciveAssessment());
        dispatch(loadEstablishmentAssessment());

        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}
export function loadAllEstablishmentBills(establishment_id, start, limit) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    let url = `/payments/invoice/${establishment_id}?start=${start}&limit=${limit}`;

    return request(url)
      .then((res) => res.json())
      .then((res) => {
        dispatch(loadEstablishmentBillsSuccess(res));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}
export function loadPaymentBills(
  establishment_id,
  period_id,
  assessment_id,
  assessment_version,
  service_type
) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    let url = "";

    url = `/payments/establishments/${establishment_id}/bills/${period_id}/${assessment_id}/${assessment_version}`;

    return request(url)
      .then((res) => res.json())
      .then((bill) => {
        dispatch(loadPaymentBillsSuccess(bill || []));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function loadEstablishmentPeriods() {
  return function (dispatch) {
    dispatch(loadEstablishmentAssessment());
  };
}

export function loadDurations(id) {
  return function (dispatch) {
    dispatch(loadEstablishmentAciveAssessment(id));
  };
}


export const GoogleMapsKey = () => async (dispatch) => {
  dispatch(beginAjaxCall());
  try {
    const response = await request(`/google-maps-key`);
    const json = await response.json();
    dispatch(endAjaxCall());
    return json.google_maps_key;  // Ensure the key is returned
  } catch (error) {
    dispatch(endAjaxCall());
    console.error(error);
    throw error;
  }
};

export const BanksList = () => (dispatch) => {
  dispatch(beginAjaxCall());
  return request(`/banks`)
    .then((res) => res.json())
    .then(
      (json) => {
        dispatch(endAjaxCall());
        return json.banks;
      },
      (error) => {
        dispatch(endAjaxCall());
        throw error;
      }
    );
};

export const deleteBankCertificate = (attachment_id) => () => {
  let url = `/users/profile/{establishment_id}/bank_certificate/attachment/${attachment_id}`;
  return request(url, "DELETE")
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const postBankCertificate = (attachment, establishment_id) => () => {
  const data = new FormData();
  data.append("attachment", attachment);

  let url = `/users/profile/${establishment_id}/bank_certificate/attachment`;
  return request(url, "POST", data, { upload: true })
    .then((res) => res.json())
    .then((res) => {
      return res.attachment;
    })
    .catch((error) => {
      throw error;
    });
};
